@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#root{
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.h1--custom{
  font-size: 30px;
}

/* Navbar css */

.custom-navbar{
  width: 100%;
  background-color: #222222;
  height: 50px;
}

.custom-navbar-content{
  width: 75%;
  margin: auto;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  z-index: 10;
  font-size: 14px;
}

.custom-nav-menu-list{
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
}

.custom-hamburger{
  display: none;
  margin-left: auto;
  cursor: pointer;
}

.custom-nav-logo{
  color: #219ebc;
  font-size: 1.2rem;
}

.custom-nav-menu{
  height: 100%;
  display: flex;
}

.nav-right{
  margin-left: auto;
}

.custom-nav-item{
  height: 100%;
  padding: 0 1rem;
}

.custom-nav-item a:hover{
  color: #fff
}

ul{
  list-style-type: none;
}

.custom-navbar a{
  text-decoration: none;
}

.custom-nav-item a{
  height: 100%;
  display: inline-block;
  text-align: center;
  padding-top: 12px;
}

.custom-navbar p,.custom-navbar a {
  color: #fff9
}

/* OTHER css*/

.leaderboard-table{
        width: 70%;
        --border: 1px solid hsl(0, 0%, 83%);
        border-radius: 10px;
        /* Don't change these properties */
        border-spacing: 0;
        border-collapse: separate;
        border: var(--border);
        overflow: hidden;
        font-weight: 600;
}

.leaderboard-header{
  background-color: #efefef;
} 

.leaderboard-header th{
  padding: 15px 40px 15px 40px;
} 

.leaderboard-content td{
  padding: 15px 40px 15px 40px;
  color:hsl(0, 1%, 30%)
}

.leaderboard-content td:nth-child(1){
  padding: 15px 40px 15px 32px;
  color:black
}

.winner-index{
  background-color: #DAA520;
  padding: 2px 10px 2px 10px !important;
}

.silver-index{
  background-color: #C0C0C0;
}

.bronze-index{
  background-color: #cd7f32;
}

/* Apply a border to the bottom of all but the last row */
.leaderboard-table>thead>tr:not(:last-child)>th,
.leaderboard-table>thead>tr:not(:last-child)>td,
.leaderboard-table>tbody>tr:not(:last-child)>th,
.leaderboard-table>tbody>tr:not(:last-child)>td,
.leaderboard-table>tfoot>tr:not(:last-child)>th,
.leaderboard-table>tfoot>tr:not(:last-child)>td,
.leaderboard-table>tr:not(:last-child)>td,
.leaderboard-table>tr:not(:last-child)>th,
.leaderboard-table>thead:not(:last-child),
.leaderboard-table>tbody:not(:last-child),
.leaderboard-table>tfoot:not(:last-child) {
 border-bottom: var(--border);
}


.progress{
  width: 50%;
  background: "black";
}

.leaderboard-user{
  padding: 5px;
}



.progress-number{
  float: left;
  margin-right: 5px;
  font-size: 12px;
}

.custom-progress-info{
  width: 100px;
  display: inline-block;
}

.custom-progress-info > span{
  margin-right: 5px;
  font-weight: 500;
  float: left;
}

.custom-progress-info > div{
  margin-top: 4px;
}

.custom-list-item td{
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.jumbotron{
  width: 100%;
  /* background-color: #eeeeee; */
  background: rgb(27,29,34);
  background: linear-gradient(93deg, rgba(27,29,34,1) 3%, rgba(44,147,180,1) 42%, rgba(34,38,42,1) 96%); 
  padding-top: 48px;
  padding-bottom: 48px;
}

.jumbotron .container{
  width: 70%;
  margin: auto;
}

.jumbotron h1 {
  font-size: 80px;
  color: #219ebc;
}
.jumbotron p{
  font-size: 30px;
  font-weight: 200;
  color: #eeeeee;
}

.contest-tab{
  margin-top: 50px;
}

.contest-info{
  width: 70%;
  margin: auto;
  padding-bottom: 400px;
}

.contest-info .contest-start{
  color: #2187a0;
  font-weight: 700;
  font-size: 25px;
  border: 4px solid #2187a0;
  padding: 5px;
  width: 250px;
  text-align: center;
  margin-bottom: 30px;
}

.contest-info span{
  font-weight: 700;
}

.footer{
  position: relative;
  clear: both;
  width: 100%;
  background-color: rgb(56,56,56);
  height: 150px;
}

.allButFooter{
  min-height: calc(100vh - 150px);
  padding-bottom: 20px;
}

.footer-logo{
  color: #219ebc;
  font-size: 25px;
  
}

.footer-content{
  width: 75%;
  margin: auto;
  color: #fff;
  padding: 10px;
}

.footer-credits{
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.footer-content p{
 margin-left: 20px;
}

.container{
  width: 70%;
  margin: auto;
}

.container-custom{
  width: 50%;
  margin: auto;
}

.form-container{
  width: 500px;
  padding:50px;
}

.form-container .row{
  width: 100%;
  align-content: center;
  margin: 20px 0;
}

.form-container .row label{
  width: 20%;
  padding: 0;
  margin: 0;
  font-weight: 600;
  text-align: center;
  line-height: 35px;
}

.form-container .row input{
  width: 80%;
}

.register-form{
  display: inline-block;
  width: 600px;
  margin-left: 27%;
}

.register-form .row label{
  width: 35%;
  padding-right: 15px;
  text-align: right;
}

.register-form .row input {
  width: 65%;
}

.options-row{
  display: flex;
  margin-bottom: 30px;
}

.options-item{
  width: 15%;
  margin-left: 20px;
  text-align: center;
}

.details-link{
  text-decoration: none;
  color: #444444;
}

.details-link:hover{
  text-decoration: underline;
  color: #df9705;
}

.contest-title{
  padding: 30px 0px;
  background-color: #eeeeee;
}

.contest-title h1 {
  width: 70%;
  margin: auto;
  flex: 1 1 auto;
}

dl > div{
  display: flex;
}

dd{
  width: 160px;
  text-align: right;
  font-weight: 600;
}

dt{
  margin-left: 20px;
  font-weight: 300;
}

.admin-panel .nav-tabs{
  border-bottom: 2px solid #222222;
}

.nav-tabs .nav-link{
  color: black;
}

.nav-link.active-tab{
  background-color: #333333;
  color: #fff;
}

.users-tab td{
  padding: 10px;
}

.contest-table th{
  font-size: 20px;
  padding: 20px 0;  
}

.contest-table thead{
  color: #2187a0;
  font-weight: 300;
  border-bottom: 2px solid #e0dede;
}

.contest-table tbody td{
  font-weight: 600;
}

.contest-table .badge{
  padding: 10px;
  font-weight: 600;
  width: 60%;
  margin: 0 15%;
}

.contest-table td{
  font-size: 17px;
  padding: 20px 0;
  height: 100%;
}

.contest-table thead tr th:first-child{
  width: 200px;
}

.contest-table a{
  display: inline-block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: inherit;
}

.contest-table a:hover{
  text-decoration: underline;
  color: #df9705;
}

/* Custom Input css */

.input-dropdown{
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
}

.input-dropdown:empty{
  border: none;
}

.dropdown-row{
  cursor: pointer;
  text-align: center;
  margin: 2px 0;
}

.search-inner{display: flex;}

/*HOME PAGE css */

.home-header{
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.home-header::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./img/homebackground.jpg');
  background-size: cover;
  transform: skew(0deg, -5deg) translateY(-200px);
}

.home-header .home-content{
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.registernow-box{
  background-color: #fff;
  height: 200px;
  text-align: center;
  position: absolute;
  top: 50px;
  right: 20%;
  padding: 20px 40px;
  border-top: 8px solid #219ebc;
}

.countdown-info{
  font-size: 30px !important;
  font-weight: 600;
}

.registernow-box h3 {
  font-family: 'Oswald', sans-serif;
  font-size: 35px;
}

.registernow-box button{
  font-size: 16px;
}

.home-info{
  width: 100%;
  min-height: 300px;
}

.contest-info-header{
  width: 100%;
  margin-top: -170px;
  text-align: center;
  position: absolute;
  z-index: 2;
}

.home-info h2{
  font-size: 5rem;
  font-weight: bold;
}

.countdown{
  padding: 50px 50px;
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;
}

.day,.hour,.minute,.second{
  font-size: 3rem;
}

.countdown .timeindicator{
  font-size: 1rem;
}

.container-day,.container-hour,.container-minute,.container-second{
  width: 100px;
  background-color: #fff;
  padding: 10px
}

.map-container::after{
  content: '';
  position: absolute;
  width: 100%;
  height: 36%;
  left:0;
  background-color: rgb(56,56,56);
  transform: skew(0deg, -5deg) translateY(-550px);
  z-index: -1;
}

.map-container{
  width: 100%;
  height: 100%;
  margin: 0;
  padding-bottom: 40px;
  background-color: rgb(56,56,56);
  margin-bottom: -20px;
}

.home-map{
  min-height: 300px;
  width: 100%;
  display: flex;
  margin:auto;
  background-color: rgb(56,56,56);
  justify-content: center;
}

.home-map .map{
  border: solid 5px #219ebc;
}

.map-text img{
  margin-right: 20px;
}

.map-text{
  width: 30%;
}

.map-text div{
  margin-top: 110px;
  float: right;
}

.map-text p{
  font-weight: 800;
  color: #fff;
  font-size: 30px;
  transform: translateY(-35px) translateX(-15px);
}

.map-localization{
  padding-left: 20px;
  padding-top: 115px;
  width: 30%;
}

.map-localization p{
  color: #fff;
  font-size: 24px;
  font-weight: 800;
}

/*All Buttons css */

.custom-button{
  background-color: #219ebc;
  border-radius: 6px;
  text-decoration: none;
  border: none;
  padding: 10px;
  width: 40%;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  margin: auto;
  transition: 0.1s ease;
}

.back-button{
  background-color:transparent;
  border: 2px solid #2187a0;
  color: #2187a0;
  font-weight: 700;
  width: 100px;
}

.home-button{
  background-color: #2187a0;
  border: 2px solid #2187a0;
  color: white;
  font-weight: 700;
  width: 100px;
}

.home-button:hover{
  background-color: white !important;
  color:#2187a0;
}

.back-button svg{
  margin-bottom: 2px;
  margin-right: 5px;
}

.back-button:hover{
  background-color: #2187a0;
  color:white;
}

.custom-button:hover{
  background-color: #2187a0;
}


.checkbox-button{
  border-radius: 25%;
  background-color:rgb(59, 192, 59);
  height: 25px;
  width: 25px;
  color:white;
  border: none;
}

.checkbox-button.crossed{
  background-color: rgb(211, 77, 77);
}

.checkbox-button.activated{
  pointer-events: none;
}

.checkbox-button:hover{
  opacity: 0.7;
}

.button-icon{
  width: 50%;
  height: 50%;
  margin-bottom: 3px;
}

.checkbox-button.crossed.disabled-custom{
  background-color: #e0dede;
  cursor: none;
  pointer-events: none;
}

.admin-option-button{
  background-color: rgb(59, 192, 59);
  color:white;
  height: 20px;
  width: 100px;
  text-decoration: none;
  border: none;
  border-radius: 5%;
  font-size: 12px;
  margin-left: 3px;
}

.admin-option-button:hover{
  opacity: 0.7;
}

.backbutton-section{
  width: 70%;
  margin: auto;
  margin-bottom: 30px !important;
}

/*Pagination css*/

.pagination-buttons{
  margin-top: 40px;
  width: 90%;
  height: 30px;
  display: flex;
  justify-content: center;
  color: #2187a0;
}

.pagination-buttons a {
  padding: 10px;
  margin: 3px;
  border-radius: 4px;
  border: 2px solid #2187a0;
  background-color: transparent;
  color:#2187a0;
  text-decoration: none;
  display: block;
  width: 40px;
  text-align: center;
}

.pagination-active a{
  background-color: #2187a0;
  color: #fff !important;
}

.pagination-buttons a:hover{
  background-color: #2187a0;
  color: #fff !important;
}

.pagination-next{
  border: none !important;
  font-weight: 700;
  margin-top: 4px !important;
}

.pagination-pervious{
  border: none !important;
  font-weight: 700;
  margin-top: 4px !important;
}

.pagination-disabled a{
  background-color: #e9dedec7;
  pointer-events: none;
  color: white;
}

.pagination-disabled a:hover{
  background-color: #e9dedec7;
}

/* leaflet css */

.leaflet-container{
  width: 33vw;
  height: 400px;
}

/* notification css */

.notification-wrapper{
  position: fixed;
  top: 10px;
  right: 10px;
  width: 300px;
  z-index: 3;
}

.notification-item{
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  border-radius: 10px;
  padding: 0 5px;
  margin-bottom: 20px;
  animation: SlideLeft 0.5s;
  animation-fill-mode: forwards;
  width: 300px;
  background-color: #fff;
  display: flex;
}

.notification-close{
  color: #777777;
}

.notification-item{
  color: rgb(59, 192, 59);
}

.notification-item p {
  padding: 5px 10px;
  margin-bottom: 0; 
  font-weight: 600;
}

.notification-text{
  width: 86%;
}

.notification-item.success {
  color:rgb(59, 192, 59);
}

.notification-item.error {
  color:rgb(211, 77, 77);
}

@keyframes SlideLeft {
  0% {
    margin-left: 120%;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes SlideRight {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 120%;
  }
}

.notification-item.exit{
  animation: SlideRight 0.5s;
  animation-fill-mode: forwards;
}

/* cookies css */


.cookies-button{
  background-color: #2187a0 !important;
  border-radius: 10px !important; 
  color: #fff !important;
  font-weight: 600;
  width: 400px;
}

.cookies-button:hover{
  background-color: #219ebc !important;
}


/* media screen css */

@media screen and (max-width:1200px) {
  .container-custom{
    width: 90%;
  }
  .registernow-box{
    right: 5%;
  }
  .contest-info-header h2{
    font-size: 4rem;
  }
  .custom-navbar-content{
    width: 90%;
  }
  .options-row .options-item{
    width: 22%;
  }

}

@media screen and (max-width:940px){
  .contest-table td{
    font-size: 14px;
  }

  .backbutton-section{
    width: 80% !important;
  }

  .admin-panel-container{
    width: 95%;
  }

  .contest-table thead tr th:first-child{
    width: 25%;
  }

  .contest-table .badge{
    padding: 5px;
    font-weight: 400;
    width: 80%;
    margin: 0;
  }

  .register-form{
    margin-left: 5%;
  }
  .register-form .row label{
    width: 100%;
    text-align: left;
  }
  .form-container .row{
    margin: 10px 0;
  }
  .register-form .row input {
    width: 250px;
  }
  .map-text{
    display: none;
  }
  .leaflet-container{
    width: 45vw;
  }
  .map-localization{
    width: 45%;
  }
  
  .details-info dd{
    text-align: left;
    max-width: 25%;
  }

  .custom-nav-menu-list{
    flex-direction: column;
  }

  .custom-nav-menu .nav-right{
    width: 100%;
  }

  .custom-navbar{
    height: 100px;
  }

  .contest-tab .container{
    width: 100%;
  }

 
}

@media screen and (max-width:800px){
  .admin-panel-container{
    width: 90%;
  }

  

  .form-container.register-form.container{
    margin: 0;
    padding: 50px 20px;
    width: 100%;
  }

  .registernow-box{
    position:initial;
    top: 0;
    width: 80%;
    margin: auto;
  }
  .contest-info-header{
    z-index: 0;
    position: relative;
    margin-top: 75px;
    margin-bottom: 50px;
    
  }
  .countdown{
    width: 80%;
    margin: auto;
  }
  .home-header::before{
    transform: skew(0deg, -5deg) translateY(-50px);
  }

  .contest-info{
    padding-bottom: 250px;
  }

  

  .details-info dd{
    min-width: 30%;
  }

}

@media screen and (max-width:640px){
  .form-container.register-form.container{
    margin: 0;
    padding: 50px 20px;
    
  }

  .options-row .options-item:first-child{
    margin-left: 0px;
  }
 
  
  .custom-navbar{
    height: 50px;
  }

  .countdown{
    width: 90%;
    padding: 50px 20px;
  }

  .contest-info{
    width: 80%;
  }

  .registernow-box{
    padding: 20px 20px;
  }

  .registernow-box h3{
    font-size: 29px;
  }

  .contest-info-header{
    margin-top: 50px;
  }

  .contest-info-header h2{
      font-size: 3rem;
  }

  .container-custom{
    width: 100%;
    margin: 0;
    padding: 10px;
  }

  .custom-hamburger{
    display: block;
  }

  .custom-navbar-content{
    width: 100%;
  }

  .custom-nav-menu-list{
    position: absolute;
    flex-direction: column;
    top: 0;
    left: -100%;
    z-index: 2;
    width: 100%;
    transition: 0.3s;
    margin-top: 50px;
    padding-bottom: 20px;
    background-color: #222222;
    justify-content: center;
    padding-top: 0;
    height: auto;
  }

  .custom-nav-menu-list.active{
    left: 0;
  }

  .nav-right{
    margin-left: 0;
  }

  .custom-nav-menu{
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
  }

  .custom-nav-item{
    height: auto;
    margin-left: 10px;
    padding: 0;
  }

  .custom-nav-item a{
    display: inline-block;
    width: 100%;
    padding: 0.6rem;
  }

  .container{
    width: 90%;
  }

  .admin-panel.container, .admin-panel-container{
    width: 100%;
    
  }
  
  .footer-content{
    width: 90%;
  }

  .contest-table thead tr th{
    font-size: 12px;
    padding: 2px
  }

  .progress{
    width: 90%;
  }

  .home-map{
    flex-direction: column;
  }

  .leaflet-container{
    width: 100%;
  }

  .map-localization{
    width: 90%;
    padding-top: 50px;
  }

  .countdown-info{
    font-size: 15px !important;
    font-weight: 600;
  }
}

@media screen and (max-width:400px){

  

  .admin-panel-container{
    width: 100%;
    margin: auto 0;
  }

  .form-container{
    padding: 50px 10px;
  }

  .registernow-box{
    width: 90%;
  }

  .registernow-box h3{
    font-size: 20px;
  }

  
}

